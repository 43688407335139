

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL4PreLab',
  components: {AiLoadingOverlay, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part4_11: null,
        part4_12: null,
        part4_13: null,
        part4_14: null,
      },
      questions: [
        {
          text: 'a) If you were asked to identify a substance, would you choose to use chemical or physical properties to identify it? Why?',
          inputName: 'part4_11',
        },
        {
          text: 'b) What could you do to water that would change only its physical properties? What could you do to chemically change water?',
          inputName: 'part4_12',
        },
        {
          text: 'c) You discover that a particular iron alloy is being used in the production of pickup truck frames. What physical AND chemical properties would you look for in a substance in order to protect the frame of the pickup truck from rust?',
          inputName: 'part4_13',
        },
        {
          text: 'd) Some substances have similar properties. Take for example, carbon monoxide $\\ce{CO}$ and nitrogen $\\ce{N2}$ . List physical and/or chemical properties that both substances have in common. Then list physical and/or chemical properties that are different between the two substances. Why is it important for all people to be aware of BOTH the similarities and the differences between the two compounds?',
          inputName: 'part4_14',
        },
      ],
    };
  },
};
